import { NavLink } from "react-router-dom"
import { useLanguageSwitcher } from "../../hooks/useLanguageSwitcher"
import { Languages } from "../../constants/languages"
import { strings } from "../../constants/strings"
import useLanguage from "../../hooks/useLanguage"

export const AppHeader = ({ innerRef }) => {
  const { t } = useLanguage()
  const [activeLanguage, switchLanguage] = useLanguageSwitcher()

  const nonActiveLanguageShort = activeLanguage === Languages.Es ? Languages.En.short : Languages.Es.short

  return (
    <header ref={innerRef} id="app-header">
      <h1 style={{ display: "none" }}>Juan Carrión</h1>

      <nav id="app-nav">
        <ul>
          <li><NavLink to="/">{t(strings.pages.home.menuTitle)}</NavLink></li>
          <li><NavLink end to="/about-me">{t(strings.pages.aboutMe.menuTitle)}</NavLink></li>
          <li><NavLink end to="/experience">{t(strings.pages.experience.menuTitle)}</NavLink></li>
          <li><NavLink end to="/education">{t(strings.pages.education.menuTitle)}</NavLink></li>
          <li><NavLink end to="/projects">{t(strings.pages.projects.menuTitle)}</NavLink></li>
          <li><NavLink end to="/get-in-touch">{t(strings.pages.getInTouch.menuTitle)}</NavLink></li>
          <li>
            <div id="app-nav-switchers">
              <div id="language-switcher" onClick={switchLanguage}>
                {nonActiveLanguageShort}
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  )
}
