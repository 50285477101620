import { PiAsteriskLight } from "react-icons/pi"
import { GoArrowRight } from "react-icons/go"
import { SkillBadgesSet } from "../skills/SkillBadgesSet"
import useLanguage from "../../hooks/useLanguage"
import { strings } from "../../constants/strings"
import { Link } from "react-router-dom"

export const ExperienceItem = ({
  id,
  type,
  time,
  shortTitle,
  organization,
  shortDescription,
  mainSkills = []
}) => {
  const { t } = useLanguage()

  return (
    <div className="experience-item">
      <div className="icon">
        <PiAsteriskLight />
      </div>
      <div className="content">
        <h2>{t(shortTitle)}</h2>
        <div className="info">
          <div className="type-and-time">
            <span className="type">{t(type.name)},</span>
            <span className="time">{t(time)}</span>
          </div>
          <div className="organization">
            <a target="_blank" rel="noreferrer" href={organization.url}>{t(organization.name)}</a>
          </div>
        </div>
        {shortDescription && <p className="short-description">{t(shortDescription)}</p>}
        <SkillBadgesSet skills={mainSkills} showThreeDots />
      </div>
      <div className="know-more">
        <Link to={`/experience/${id}`}><span className="ib-middle">{t(strings.pages.experience.itemKnowMore)}</span> <GoArrowRight /></Link>
      </div>
    </div>
  )
}
