import { experienceData } from "../../data/experienceData"
import { ExperienceItem } from "./ExperienceItem"

export const ExperienceList = () => {
  const experiences = Object
    .entries(experienceData.experiences)
    .map(([key, value]) => ({ id: key, ...value }))
    .sort((a, b) => a.relevance > b.relevance ? 1: -1)

  return (
    <div id="experience-list">
      {experiences.map((experience) => 
        <ExperienceItem key={experience.id} {...experience} />
      )}
    </div>
  )
}
