import { useContext } from "react";
import { MainContext } from "../context/MainContext";

const useAppHeaderHeight = () => {
  const context = useContext(MainContext)

  return context.appHeaderHeight
}

export default useAppHeaderHeight
