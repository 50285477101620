import { LanguageContextProvider } from "./context/LanguageContext";
import { MainContextProvider } from "./context/MainContext";
import { routes } from "./routes";
import { useRoutes } from "react-router";
import "./css/app.css"

const App = () => {
  let routesElement = useRoutes(routes)

  return (
    <LanguageContextProvider>
      <MainContextProvider>
        {routesElement}
      </MainContextProvider>
    </LanguageContextProvider>
  )
}

export default App
