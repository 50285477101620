import { SkillBadge } from "./SkillBadge"
import { PiDotsThree } from "react-icons/pi"

export const SkillBadgesSet = ({ skills, title, showThreeDots }) => {
  return (
    <div className="skill-badges-set">
      {title && <h3 className="heading">{title}</h3>}
      <div>
        {skills.map(skill => <SkillBadge key={skill.id} {...skill} />)}
        {showThreeDots && <div className="skill-badge three-dots"><PiDotsThree /></div>}
      </div>
    </div>
  )
}
