import { Link } from "react-router-dom"
import { strings } from "../constants/strings"
import useDocumentTitle from "../hooks/useDocumentTitle"
import useLanguage from "../hooks/useLanguage"
import profileImage from "../images/about/profile.jpg"

export const AboutMe = () => {
  const { t } = useLanguage()

  useDocumentTitle(`${t(strings.pages.aboutMe.title)} – Juan Carrión`)

  return (
    <article id="about-page" className="centered-page">
      <h1 className="heading">{t(strings.pages.aboutMe.title)}</h1>
      <div className="body">
        <img className="about-profile-picture" src={profileImage} alt={t(strings.pages.aboutMe.profilePictureAlt)} />
        {t(strings.pages.aboutMe.body)}  
      </div>
    </article>
  )
}
