import { strings } from "../constants/strings"
import useDocumentTitle from "../hooks/useDocumentTitle"
import useLanguage from "../hooks/useLanguage"

export const GetInTouch = () => {
  const { t } = useLanguage()

  useDocumentTitle(`${t(strings.pages.getInTouch.title)} – Juan Carrión`)

  return (
    <article id="about-page" className="centered-page">
      <h1 className="heading">{t(strings.pages.getInTouch.title)}</h1>
    </article>
  )
}
