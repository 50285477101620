import useWindowSize from "../../hooks/useWindowSize"
import useAppHeaderHeight from "../../hooks/useAppHeaderHeight"
import heroProfile from "../../images/hero/profile.jpg"
import backendIcon from "../../images/hero/backend.svg"
import designIcon from "../../images/hero/design.svg"
import kotlinIcon from "../../images/hero/kotlin.svg"
import soundIcon from "../../images/hero/sound.svg"
import { HeroBannerItem } from "./HeroBannerItem"
import { strings } from "../../constants/strings"

export const Hero = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const appHeaderHeight = useAppHeaderHeight()

  const heroHeight = windowHeight - appHeaderHeight

  const h1AproxHeight = windowWidth / 8.47
  const h1Top = heroHeight * 0.41 - h1AproxHeight / 2
  const h1FontSize = windowWidth / 159 + "rem"

  const profileWidth = 500
  const profileLeft = windowWidth * 0.397 - profileWidth / 2

  return (
    <div id="hero"
      style={{
        height: heroHeight
      }}
    >
      <h1
        style={{
          top: h1Top,
          fontSize: h1FontSize
        }}
      >
        Juan <span className="spacer"></span>Carrión
      </h1>
      <div
        className="profile"
        style={{
          width: profileWidth + "px",
          left: profileLeft + "px",
          backgroundImage: `url(${heroProfile})`
        }}
      >
      </div>
      <div className="banner">
        <ul>
          <HeroBannerItem icon={backendIcon} title={strings.pages.home.heroBanner.webBackend} />
          <HeroBannerItem icon={kotlinIcon} title={strings.pages.home.heroBanner.kotlin} />
          <HeroBannerItem icon={designIcon} title={strings.pages.home.heroBanner.uxUi} wip />
          <HeroBannerItem icon={soundIcon} title={strings.pages.home.heroBanner.soundTech} wip />
        </ul>
      </div>
    </div>
  )
}
