import { AboutMe } from "./pages/AboutMe";
import { Education } from "./pages/Education";
import { Experience } from "./pages/Experience";
import { GetInTouch } from "./pages/GetInTouch";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { Projects } from "./pages/Projects";
import { SingleExperience } from "./pages/SingleExperience";

export const routes = [
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/about-me",
    element: <AboutMe />
  },
  {
    path: "/education",
    element: <Education />
  },
  {
    path: "/experience",
    children: [
      {
        index: true,
        element: <Experience />,
      },
      {
        path: "/experience/:id",
        element: <SingleExperience />
      }
    ]
  },
  {
    path: "/get-in-touch",
    element: <GetInTouch />
  },
  {
    path: "/projects",
    element: <Projects />
  },
  {
    path: "*",
    element: <NotFound />
  }
]
