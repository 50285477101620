import { useParams } from "react-router-dom"
import { experienceData } from "../data/experienceData"
import { NotFound } from "./NotFound"
import useLanguage from "../hooks/useLanguage"
import { strings } from "../constants/strings"
import { BackToExperiencePageButton } from "../components/experience/BackToExperiencePageButton"
import { SkillBadgesSet } from "../components/skills/SkillBadgesSet"

export const SingleExperience = () => {
  const { id } = useParams()
  const { t } = useLanguage()

  const experiences = experienceData.experiences

  if (!experiences.hasOwnProperty(id)) {
    return <NotFound />
  }

  const experience = experiences[id]

  return (
    <div id="single-experience-page" className="centered-page">
      <h1 className="heading">{t(strings.pages.experience.title)}</h1>
      <div className="body">
        <div className="pre-heading">
          <BackToExperiencePageButton />
        </div>
        <h2 className="heading">{t(experience.title)}</h2>
        <div className="info">
          <div className="type-and-time">
            <span className="type">{t(experience.type.name)},</span>
            <span className="time">{t(experience.time)}</span>
          </div>
          <div className="organization">
            <a target="_blank" rel="noreferrer" href={experience.organization.url}>{t(experience.organization.name)}</a>
          </div>
        </div>
        <div className="description">{t(experience.advancedDescription)}</div>
        <SkillBadgesSet title={t(strings.pages.singleExperience.mostRelevantSkillsAndTechnologies)} skills={experience.mainSkills} />
        <div className="full-skills-disclosure">
          {experience.programmingLanguages &&
            <SkillBadgesSet title={t(strings.pages.singleExperience.languages)} skills={experience.programmingLanguages} />}
          {experience.computerScienceParadigms &&
            <SkillBadgesSet title={t(strings.pages.singleExperience.paradigms)} skills={experience.computerScienceParadigms} />}
          {experience.technologies &&
            <SkillBadgesSet title={t(strings.pages.singleExperience.technologies)} skills={experience.technologies} />}
          {experience.developmentMethodologies &&
            <SkillBadgesSet title={t(strings.pages.singleExperience.developmentMethodologies)} skills={experience.developmentMethodologies} />}
          {experience.developementTools &&
            <SkillBadgesSet title={t(strings.pages.singleExperience.developmentTools)} skills={experience.developementTools} />}
        </div>
      </div>
    </div>
  )
}
