import { useContext } from "react"
import { LanguageContext } from "../context/LanguageContext"
import { Languages } from "../constants/languages"

export const useLanguageSwitcher = () => {
  const { activeLanguage, setLanguage } = useContext(LanguageContext)

  function switchLanguage() {
    const newLanguage = activeLanguage === Languages.Es ? Languages.En : Languages.Es
    localStorage.language = newLanguage.tag
    setLanguage(newLanguage)
  }

  return [activeLanguage, switchLanguage]
}
