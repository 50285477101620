import { Link } from "react-router-dom"

export const TODO = "{Information not available at the moment}"

export const strings = {
  pages: {
    aboutMe: {
      title: {
        en: "About me",
        es: "Sobre mí"
      },
      menuTitle: {
        en: "About me",
        es: "Sobre mí"
      },
      body: {
        en: <>
          <p>Hi! I'm Juan Carrión, although people usually call me Carri. I am a Software Engineer with <strong>experience in Kotlin, Spring and PHP backend and interests in Software Architecture and full stack development</strong>.</p>
          <p>I began programming at 15, building some basic websites written in PHP, with an Apache server and a free domain, and relying on <a href="https://userspice.com/#:~:text=If%20you%27re%20not%20aware%2C%20the%20UserCake%20project%20was%20shut%20down%20in%20the%20spring%20of%202017." target="_blank" rel="noreferrer">a small framework</a>.</p>
          <p>My first project with some public exposure came at 16: I developed a web app to take online <strong>my high school's entire book bank</strong>. I created a monolithic system in PHP with MySQL that managed subjects, books, requests, loans and returns, with a control panel and a page for each student to make their queries. The app received constant updates for years, as I continued to learn, until it was consolidated as <a href="#">Lyra</a> in 2021.</p>
          <p>Since then, and during the first years of university, I developed different websites and apps for associations and other entities, generally as a volunteer.</p>
          <p>In 2017 I began my studies in <strong>Computer Science Engineering at the University of Murcia</strong>, where I learned the fundamentals of Programming and Computer Science, and where I began to strengthen my development skills. In 2019 I transfered to the <strong>Complutense University of Madrid</strong>, where I delved into topics such as communications, computer networks and Software Engineering, also entering areas such as Artificial Intelligence and cybersecurity.</p>
          <p>During my high school and university years, I have always been actively involved in student representation and volunteering organizations. On the <a href="#">Education</a> and <a href="#">Projects</a> pages you can take a look at my experiences and projects from those years, in software, events and other types.</p>
          <p>In 2022 I joined the development team of Docline, a Digital Health startup, where I have been able to learn alongside great professionals and develop my skills as an engineer, working with technologies such as <strong>Kotlin and Spring</strong>, although I started with PHP and Symfony. I invite you to learn more about my professional experience on the <Link to="/experience">Experience</Link> page.</p>
          <p>Currently, I continue at Docline as a Kotlin Backend Engineer and I am looking forward to <strong>expand my training in advanced aspects</strong> of Software Architecture, frontend, frameworks such as Spring, scalability, concurrency and event-based communication, among others.</p>
          <p>On the other hand, occasionally, I participate in musical and theatrical events as a stage manager or sound technician, as well as a DJ, and among my future plans is to get professional training in sound engineering, in parallel with my work as a Software Engineer.</p>
        </>,
        es: <>
          <p>¡Hola! Me llamo Juan Carrión, aunque suelen llamarme Carri. Soy ingeniero informático con <strong>experiencia en backend Kotlin, Spring y PHP y con intereses en Arquitectura de Software y full stack</strong>.</p>
          <p>Con 15 años comenzaba a dar mis primeros pasos en programación, construyendo algunas páginas web básicas en PHP, con un servidor Apache y un dominio gratuito, y apoyándome en <a href="https://userspice.com/#:~:text=If%20you%27re%20not%20aware%2C%20the%20UserCake%20project%20was%20shut%20down%20in%20the%20spring%20of%202017." target="_blank" rel="noreferrer">un pequeño framework</a>.</p>
          <p>Mi primer proyecto con algo de público llegó con 16 años: desarrollé una aplicación web para digitalizar <strong>todo el banco de libros de mi instituto</strong>. Creé un sistema monolítico en PHP con MySQL que gestionaba asignaturas, libros, solicitudes, préstamos y devoluciones, con un panel de control y una página para que cada estudiante pudiera realizar sus consultas. La aplicación recibió actualizaciones constantes durante años, conforme iba aprendiendo, hasta consolidarse como <a href="#">Lyra</a> en 2021.</p>
          <p>Desde entonces y durante los primeros años de universidad, desarrollé distintas páginas y aplicaciones web para asociaciones y otras entidades, en general de manera voluntaria.</p>
          <p>En 2017 comencé mis estudios de <strong>Ingeniería Informática en la Universidad de Murcia</strong>, donde aprendí los fundamentos de la Programación y la Informática, y donde comencé a dar solidez a mis capacidades de desarrollo. En 2019 me trasladé a la <strong>Universidad Complutense de Madrid</strong>, donde profundicé en temas como las telecomunicaciones, las redes y la Ingeniería del Software, entrando además en áreas como la inteligencia artificial y la seguridad informática.</p>
          <p>Durante mis años de instituto y de universidad siempre ha participado activamente en organizaciones de <strong>representación estudiantil y voluntariado</strong>. En las páginas de <a href="#">Educación</a> y <a href="#">Proyectos</a> puedes echar un vistazo a mis experiencias y proyectos de esos años, tanto de software como de eventos y otros tipos.</p>
          <p>En 2022 me incorporé al equipo de desarrollo de Docline, una startup de salud digital, donde he podido aprender al lado de grandes profesionales y desarrollar mis capacidades como ingeniero, trabajando con tecnologías como <strong>Kotlin y Spring</strong>, aunque comencé con PHP y Symfony. Te invito a conocer más sobre mi experiencia profesional en la página de <Link to="/experience">Experiencia</Link>.</p>
          <p>Actualmente continúo en Docline como ingeniero de backend en Kotlin y busco <strong>ampliar mi formación en aspectos avanzados</strong> de Arquitectura del Software, frontend, frameworks como Spring, escalabilidad, concurrencia y comunicación basada en eventos, entre otros.</p>
          <p>Por otro lado, de manera puntual, suelo participar en eventos musicales y teatrales como regidor o técnico de sonido, además de DJ, y entre mis planes de futuro se encuentra formarme en ingeniería de sonido de manera profesional, en paralelo con mi trabajo como ingeniero informático.</p>
        </>
      },
      profilePictureAlt: {
        en: "Profile picture",
        es: "Foto de perfil"
      }
    },
    education: {
      title: {
        en: "Education",
        es: "Educación"
      },
      menuTitle: {
        en: "Education",
        es: "Educación"
      }
    },
    experience: {
      title: {
        en: "Experience",
        es: "Experiencia"
      },
      menuTitle: {
        en: "Experience",
        es: "Experiencia"
      },
      intro: {
        en: <>
          <p>Welcome to my professional experience page. Take a look at the most relevant items of these years, as a Software Engineer and as a Sound Technician<sup>WIP</sup>.</p>
          <p>If you'd like to see a more detailed view, click on the <i>Know more</i> button of each item.</p>
        </>,
        es: <>
          <p>Bienvenidx a mi experiencia profesional. Aquí puedes echar un vistazo a los elementos más relevantes de estos años, como ingeniero de software y como técnico de sonido<sup>WIP</sup>.</p>
          <p>Si quieres saber más, haz click en el botón <i>Detalles</i> de cada elemento.</p>
        </>
      },
      itemKnowMore: {
        en: "Know more",
        es: "Detalles"
      }
    },
    getInTouch: {
      title: {
        en: "Get in touch",
        es: "Contacto"
      },
      menuTitle: {
        en: "Get in touch",
        es: "Contacto"
      }
    },
    home: {
      title: {
        en: "Hi!",
        es: "¡Hola!"
      },
      menuTitle: {
        en: "Juan Carrión",
        es: "Juan Carrión"
      },
      heroBanner: {
        webBackend: {
          en: "Web & Backend",
          es: "Web y backend"
        },
        kotlin: {
          en: "Kotlin",
          es: "Kotlin"
        },
        uxUi: {
          en: "UX/UI",
          es: "UX/UI"
        },
        soundTech: {
          en: "Sound tech",
          es: "Sistemas de sonido"
        }
      }
    },
    notFound: {
      title: {
        en: "Not found",
        es: "No encontrado"
      },
      menuTitle: {
        en: "Not found",
        es: "No encontrado"
      }
    },
    projects: {
      title: {
        en: "Projects & volunteering",
        es: "Proyectos y voluntariado"
      },
      menuTitle: {
        en: <>Projects <span className="and">& volunteering</span></>,
        es: <>Proyectos <span className="and">y voluntariado</span></>
      }
    },
    singleExperience: {
      mostRelevantSkillsAndTechnologies: {
        en: "Most relevant skills and technologies",
        es: "Habilidades y tecnologías más destacadas"
      },
      languages: {
        en: "Languages",
        es: "Lenguajes"
      },
      paradigms: {
        en: "Paradigms",
        es: "Paradigmas"
      },
      technologies: {
        en: "Technologies",
        es: "Tecnologías"
      },
      developmentMethodologies: {
        en: "Development methodologies",
        es: "Metodologías de desarrollo"
      },
      developmentTools: {
        en: "Development tools",
        es: "Herramientas de desarrollo",
      }
    },
  },
  components: {
    experience: {
      backToExperiencePageButton: {
        text: {
          en: "Back to Experience",
          es: "Volver a Experiencia"
        }
      }
    }
  },
  others: {
    footer: {
      builtWithReact: {
        en: "Built with React",
        es: "Hecho con React"
      },
    }
  }
}
