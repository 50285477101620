import { educationData } from "../../data/educationData"
import { EducationItem } from "./EducationItem"

export const EducationList = () => {
  const educationMilestones = Object
    .entries(educationData.milestones)
    .map(([key, value]) => ({ key: key, ...value }))

  return (
    <div id="education-list">
      {educationMilestones.map((milestone) => 
        <EducationItem key={milestone.key} {...milestone} />
      )}
    </div>
    )
}
