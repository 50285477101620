import { ExperienceList } from "../components/experience/ExperienceList"
import { strings } from "../constants/strings"
import useDocumentTitle from "../hooks/useDocumentTitle"
import useLanguage from "../hooks/useLanguage"

export const Experience = () => {
  const { t } = useLanguage()

  useDocumentTitle(`${t(strings.pages.experience.title)} – Juan Carrión`)

  return (
    <article id="experience-page" className="centered-page">
      <h1 className="heading">{t(strings.pages.experience.title)}</h1>
      <div className="body">{t(strings.pages.experience.intro)}</div>
      <ExperienceList />
    </article>
  )
}
