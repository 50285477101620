import { GoArrowLeft } from "react-icons/go"
import { useNavigate } from "react-router-dom"
import { strings } from "../../constants/strings"
import useLanguage from "../../hooks/useLanguage"

export const BackToExperiencePageButton = () => {
  const { t } = useLanguage()
  const navigate = useNavigate()

  function navigateToExperiencePage() {
    navigate("/experience")
  }

  return (
    <div id="back-to-experience-list-button" onClick={navigateToExperiencePage}>
      <GoArrowLeft /> <span className="ib-middle">{t(strings.components.experience.backToExperiencePageButton.text)}</span>
    </div>
  )
}
