import { AppHeader } from "../components/shared/AppHeader";
import { AppFooter } from "../components/shared/AppFooter";
import { createContext, useEffect, useRef, useState } from "react";

export const MainContext = createContext(undefined)

export const MainContextProvider = ({ children }) => {
  const appHeaderRef = useRef(null)
  const [appHeaderHeight, setAppHeaderHeight] = useState(0)

  useEffect(() =>  {
    setAppHeaderHeight(appHeaderRef.current.offsetHeight)
  }, [])

  return (
    <MainContext.Provider value={{ appHeaderHeight: appHeaderHeight }}>
      <AppHeader innerRef={appHeaderRef} />
        {children}
      <AppFooter />
    </MainContext.Provider>
  )
}
