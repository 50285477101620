import ucmLogo from "../images/education/ucm.svg"
import umuLogo from "../images/education/umu.svg"
import iaxLogo from "../images/education/iax.png"

export const educationData = {
  milestones: {
    ucm: {
      time: "2019 - 2023",
      title: {
        es: "Grado en Ingeniería Informática",
        en: "Degree in Computer Science Engineering"
      },
      organization: {
        name: {
          es: "Universidad Complutense de Madrid",
          en: "Complutense University of Madrid"
        },
        logo: ucmLogo,
        url: "https://www.ucm.es"
      },
      description: "Tercer año con programa SICUE. Tras la buena experiencia y los buenos resultados, decidí realizar un traslado de expediente definitivo, y terminar la carrera en la UCM.", // Review this
      githubLinks: [ // Review this
        {
          title: "aplicación web para institutos de Educación Secundaria en PHP y JavaScript",
          url: "https://github.com/juancrrn/awsw-gesi"
        },
        {
          title: "aplicación distribuida de tienda online con AI en Java y JavaScript con Spring Framework",
          url: "https://github.com/juancrrn/cbers-ennvas"
        }
      ]
    },
    umu: {
      time: "2017 - 2019",
      title: {
        es: "Grado en Ingeniería Informática",
        en: "Degree in Computer Science Engineering"
      },
      organization: {
        name: {
          es: "Universidad de Murcia",
          en: "University of Murcia"
        },
        logo: umuLogo,
        url: "https://www.um.es"
      },
      description: "Realicé los dos primeros cursos de Ingeniería Informática en Murcia.", // Review this
      githubLinks: [ // Review this
        {
          title: "aplicación web para institutos de Educación Secundaria en PHP y JavaScript",
          url: "https://github.com/juancrrn/awsw-gesi"
        },
        {
          title: "aplicación distribuida de tienda online con AI en Java y JavaScript con Spring Framework",
          url: "https://github.com/juancrrn/cbers-ennvas"
        }
      ]
    },
    iax: {
      time: "2011 - 2017",
      title: {
        es: "Bachillerato tecnológico bilingüe de francés",
        en: "French-bilingual Technological High School Diploma"
      },
      organization: {
        name: {
          es: "Instituto de Educación Secundaria Alfonso X el Sabio",
          en: "Alfonso X el Sabio High School"
        },
        logo: iaxLogo,
        url: "https://www.iax.es"
      },
      description: {
        es: "Realicé mis estudios de Secundaria en este centro, donde, además, tuve la oportunidad de participar en una gran variedad de proyectos educativos, que detallo más adelante.",
        en: "TODO"
      }
    }
  }
}
