import useLanguage from "../../hooks/useLanguage"

export const SkillBadge = ({ name, icon }) => {
  const { t } = useLanguage()

  return (
    <div className="skill-badge">
      <img className="icon" src={icon} alt={t(name)} />
      <span className="name">{t(name)}</span>
    </div>
  )
}
