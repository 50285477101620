import { strings } from "../../constants/strings"
import useLanguage from "../../hooks/useLanguage"

export const AppFooter = () => {
  const year = new Date().getFullYear()

  const { t } = useLanguage()

  return (
    <footer id="app-footer">
      <div>
        {year} &copy; Juan Carrión
      </div>
      <div>
        {t(strings.others.footer.builtWithReact)}
      </div>
    </footer>
  )
}
