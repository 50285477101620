import useLanguage from "../../hooks/useLanguage"

export const HeroBannerItem = ({ icon, title, wip = false, alt = "" }) => {
  const { t } = useLanguage()

  const wipSup = wip === true ? <sup>WIP</sup> : ""

  return (
    <li>
      <span className="icon"><img src={icon} alt={alt} /></span>
      <span className="text">{t(title)}{wipSup}</span>
    </li>
  )
}
