import { Link } from "react-router-dom"
import { skillsData } from "./skillsData"
import { TODO } from "../constants/strings"

const skills = skillsData.skills

export const ExperienceTypes = {
  Software: {
    name: {
      es: "Ingeniero de software",
      en: "Software Engineer"
    }
  },
  Dj: {
    name: {
      es: "DJ",
      en: "DJ"
    }
  },
  SoundTech: {
    name: {
      es: "Técnico de sonido",
      en: "Sound tech"
    }
  }
}

export const JobTypes = {
  Freelance: "freelance",
  Employee: "employee"
}

const Organizations = {
  Iax: {
    name: {
      es: "IES Alfonso X el Sabio",
      en: "Alfonso X el Sabio High School"
    },
    url: "https://www.iax.es"
  }
}

export const experienceData = {
  experiences: {
    "docline-kotlin": {
      type: ExperienceTypes.Software,
      jobType: JobTypes.Employee,
      relevance: -1,
      time: {
        es: "2022 - ahora (12 meses)",
        en: "2022 - present (12 months)"
      },
      active: true,
      shortTitle: {
        es: "Ingeniero de backend Kotlin",
        en: "Kotlin Backend Engineer"
      },
      title: {
        es: "Ingeniero mid-senior de backend en microservicios, Kotlin y Spring Framework",
        en: "Mid-Senior Backend Engineer in Microservices, Kotlin and Spring Framework"
      },
      organization: {
        name: {
          es: "Docline",
          en: "Docline"
        },
        url: "https://docline.com"
      },
      shortDescription: {
        es: "Diseño y desarrollo de distintos microservicios cloud en Kotlin con Spring Framework.",
        en: "Design and develpement of multiple cloud microservices in Kotlin with Spring Framework."
      },
      advancedDescription: {
        es: <>
          <p>Docline es una plataforma de salud digital integral que ofrece diferentes servicios destinados a conectar profesionales médicos y pacientes. Formo parte de su equipo de TI desde marzo de 2022, cuando entré como <Link to="/experience/docline-php">desarrollador junior PHP</Link>. Tras unos meses en dicho puesto, me trasladé al recién creado equipo de Kotlin.</p>
          <p>Durante este año como desarrollador Kotlin, compaginado con las últimas partes del <Link to="/education/ucm">Grado en Ingeniería Informática</Link>, he tenido la oportunidad de desarrollar una serie de microservicios cloud basados en Spring Framework y Spring Boot, aplicando principios como <i>domain-driven design</i> (DDD), arquitectura hexagonal y enfoque en <i>testing</i> unitario, de integración y funcional.</p>
          <p>En base a dichos principios y con la ayuda de Spring Framework, he desarrollado aplicaciones de backend comunicadas mediante API REST o eventos y compuestas por casos de uso o <i>handlers</i>, lógica de negocio en el dominio, servicios, repositorios, entidades y <i>mappers</i>. He podido desarrollar algunos de estos proyectos desde cero, participando en su diseño y definición.</p>
        </>,
        en: <>
          <p>Docline is a comprehensive digital health platform that offers a variety of services to connect medical professionals and patients. I have been a member of their IT team since March 2022, when I joined as a <Link to="/experience/docline-php">junior PHP developer</Link>. After a few months in this position, I moved to the newly created Kotlin team.</p>
          <p>During this year as a Kotlin developer, combined with the last steps of the <Link to="/education/ucm">Degree in Computer Science Engineering</Link>, I have had the opportunity to develop a series of cloud-based microservices built on Spring Framework and Spring Boot, making use of principles such as domain-driven design (DDD), hexagonal architecture and focus on unit, integration and functional testing.</p>
          <p>Based on these principles and with the help of Spring Framework, I have developed backend applications communicated through REST APIs or events, and composed of use cases or handlers, business logic in the domain layer, services, repositories, entities and mappers. I have been able to develop some of these projects from scratch, participating in their design and definition.</p>
        </>
      },
      mainSkills: [
        skills.ProgrammingLanguages.Kotlin,
        skills.ComputerScienceParadigms.Microservices,
        skills.Technologies.SpringFramework
      ],
      programmingLanguages: [
        skills.ProgrammingLanguages.Kotlin
      ],
      computerScienceParadigms: [
        skills.ComputerScienceParadigms.Microservices,
        skills.ComputerScienceParadigms.DomainDrivenDesign,
        skills.ComputerScienceParadigms.HexagonalArchitecture,
        skills.ComputerScienceParadigms.ReactiveProgramming,
        skills.ComputerScienceParadigms.Cqrs,
        skills.ComputerScienceParadigms.Rest,
        skills.ComputerScienceParadigms.FunctionalTesting,
        skills.ComputerScienceParadigms.IntegrationTesting
      ],
      developmentMethodologies: [
        skills.DevelopmentMethodologies.Scrum
      ],
      technologies: [
        skills.Technologies.SpringFramework,
        skills.Technologies.HibernateReactive,
        skills.Technologies.OpenAPI,
        skills.Technologies.RabbitMQ,
      ],
      developementTools: [
        skills.DevelopmentTools.Mac,
        skills.DevelopmentTools.IntelliJ,
        skills.DevelopmentTools.Docker,
        skills.DevelopmentTools.Git,
        skills.DevelopmentTools.GitLab,
        skills.DevelopmentTools.BitBucket,
        skills.DevelopmentTools.Maven
      ]
    },
    "docline-php": {
      type: ExperienceTypes.Software,
      jobType: JobTypes.Employee,
      relevance: 0,
      time: {
        es: "2022 (6 meses)",
        en: "2022 (6 months)"
      },
      active: true,
      shortTitle: {
        es: "Ingeniero junior de backend PHP",
        en: "Junior PHP Backend Engineer"
      },
      title: {
        es: "Ingeniero junior de backend en microservicios, PHP y Symfony",
        en: "Junior Backend Engineer in Microservices, PHP and Symfony"
      },
      organization: {
        name: {
          es: "Docline",
          en: "Docline"
        },
        url: "https://docline.com"
      },
      shortDescription: {
        es: "Diseño y desarrollo de microservicio backend-for-frontend cloud en PHP con Symfony.",
        en: "Design and develpement of cloud backend-for-frontend microservice in PHP with Symfony."
      },
      advancedDescription: {
        es: TODO,
        en: TODO
      },
      mainSkills: [
        skills.ProgrammingLanguages.Php,
        skills.ComputerScienceParadigms.Microservices,
        skills.Technologies.Symfony
      ],
      skills: [
        skills.ProgrammingLanguages.Php,
        skills.ComputerScienceParadigms.Microservices,
        skills.Technologies.Symfony
      ]
    },
    "floridablanca-high-school-website": {
      type: ExperienceTypes.Software,
      jobType: JobTypes.Freelance,
      relevance: 1,
      time: {
        es: "2020 (3 meses)",
        en: "2020 (3 months)"
      },
      shortTitle: {
        es: "Desarrollo de web corporativa",
        en: "Development of corporate website"
      },
      title: {
        es: "Diseño y desarrollo de web corporativa",
        en: "Design and development of corporate website"
      },
      organization: {
        name: {
          es: "IES Floridablanca",
          en: "Floridablanca High School"
        },
        url: "https://iesfloridablanca.es"
      },
      advancedDescription: {
        es: "Estudio de requisitos, diseño, desarrollo e implementación de la web informativa del Instituto de Educación Secundaria Floridablanca de Murcia.",
        en: TODO
      },
      mainSkills: [
        skills.Technologies.WordPress
      ],
      skills: [
        skills.Technologies.WordPress,
        skills.ProgrammingLanguages.Html,
        skills.ProgrammingLanguages.Css
      ],
      links: [
        {
          title: {
            es: "Web corporativa",
            en: "Corporate website"
          },
          url: "https://iesfloridablanca.es"
        }
      ]
    },
    "cpcrm-website": {
      type: ExperienceTypes.Software,
      jobType: JobTypes.Freelance,
      relevance: 1,
      time: {
        es: "2020 (3 meses)",
        en: "2020 (3 months)"
      },
      shortTitle: {
        es: "Desarrollo de web y app corporativas",
        en: "Development of corporate website and app"
      },
      title: {
        es: "Diseño y desarrollo de web y app corporativas",
        en: "Design and development of corporate website and app"
      },
      organization: {
        name: {
          es: "Colegio Profesional de Criminología de la Región de Murcia",
          en: "Professional Association of Criminology of the Region of Murcia"
        },
        url: "https://www.criminologiarm.es"
      },
      shortDescription: {
        es: "Estudio de requisitos, diseño y desarrollo de una página web y de una app web de directorio.",
        en: "Requirements analysis, design and development of a website and a directory web app."
      },
      advancedDescription: {
        es: TODO,
        en: TODO
      },
      mainSkills: [
        skills.ProgrammingLanguages.Php,
        skills.Technologies.WordPress
      ],
      skills: [
        skills.Technologies.WordPress,
        skills.ProgrammingLanguages.Html,
        skills.ProgrammingLanguages.Css
      ],
      links: [
        {
          title: {
            es: "Web corporativa",
            en: "Corporate website"
          },
          url: "https://www.criminologiarm.es"
        },
        {
          title: {
            es: "App web intranet de directorio",
            en: "Intranet directory web app"
          },
          url: "https://www.criminologiarm.es/directorio"
        }
      ]
    },
    "canae-doc-app": {
      type: ExperienceTypes.Software,
      jobType: JobTypes.Freelance,
      relevance: 2,
      time: {
        es: "2019 (3 meses)",
        en: "2019 (3 months)"
      },
      shortTitle: {
        es: "Desarrollo de app de documentación",
        en: "Development of a documentation app"
      },
      title: {
        es: "Diseño y desarrollo de app de documentación",
        en: "Design and development of a documentation app"
      },
      organization: {
        name: {
          es: "Canae Confederación Estatal de Asociaciones de Estudiantes",
          en: "Canae National Board of Student Unions"
        },
        url: "https://canae.org"
      },
      shortDescription: {
        es: "Diseño y desarrollo de una app web de documentación para las asambleas de la Asociación.",
        en: "Design and development of a web app for the documentation of the Association meetings."
      },
      advancedDescription: {
        es: TODO,
        en: TODO
      },
      mainSkills: [
        skills.ProgrammingLanguages.Php
      ],
      skills: [
        skills.ProgrammingLanguages.Php
      ]
    },
    "alfonso-x-high-school-sound-system": {
      type: ExperienceTypes.SoundTech,
      jobType: JobTypes.Freelance,
      relevance: 3,
      time: {
        es: "2019 (3 meses)",
        en: "2019 (3 months)"
      },
      shortTitle: {
        es: "Instalación de sistema de sonido en salón de actos",
        en: "Auditorium sound system setup"
      },
      title: {
        es: "Ingeniería e instalación de sistema de sonido para un salón de actos",
        en: "Design and installation of a sound system for an auditorium"
      },
      organization: Organizations.Iax,
      shortDescription: {
        es: "Diseño e instalación de un sistema de sonido para el salón de actos de un instituto.",
        en: "Design and set up of a sound system for a high school auditorium."
      },
      advancedDescription: {
        es: "Estudio de requisitos, diseño e instalación, eléctrica y electroacústica, de un sistema de sonido en el salón de actos de un instituto de Secundaria de Murcia. Supervisado por un instalador eléctrico autorizado. Montaje de cuadro eléctrico y rack de sonido. Uso de mesa digital Soundcraft Ui16, micrófonos Shure SM58 y altavoces LD Systems MAUI 11 G2.",
        en: TODO
      },
      mainSkills: [
        skills.SoundTech.PaEngineering
      ],
      skills: [
        skills.SoundTech.PaEngineering
      ]
    }
  }
}
