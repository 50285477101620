import { PiAsteriskLight } from "react-icons/pi"
import { GoArrowRight } from "react-icons/go"
import useLanguage from "../../hooks/useLanguage"

export const EducationItem = ({ title, time, organization }) => {
  const { t } = useLanguage()

  return (
    <div className="education-item">
      <div className="icon">
        <PiAsteriskLight />
      </div>
      <div className="content">
        <h2>{t(title)}</h2>
        <div className="info">
          <div className="type-and-time">
            <span className="time">{time}</span>
          </div>
          <div className="organization">
            <img className="logo" src={organization.logo} alt={t(organization.name)} />
            <a target="_blank" rel="noreferrer" href={organization.url}>{t(organization.name)}</a>
          </div>
        </div>
      </div>
      <div className="know-more">
        <span className="ib-middle">Know more</span> <GoArrowRight />
      </div>
    </div>
  )
}
