import kotlinIcon from "../images/icons/kotlin-48x48.svg"
import thinClientIcon from "../images/icons/thin-client-48x48.svg"
import springIcon from "../images/icons/spring-48x48.svg"
import wordpressIcon from "../images/icons/wordpress-48x48.svg"
import htmlIcon from "../images/icons/html-48x48.svg"
import cssIcon from "../images/icons/css-48x48.svg"
import phpIcon from "../images/icons/php-48x48.svg"
import soundWaveIcon from "../images/icons/sound-wave-48x48.svg"
import symfonyIcon from "../images/icons/symfony-48x48.svg"
import accuracyIcon from "../images/icons/accuracy.svg"
import hexagonIcon from "../images/icons/hexagon.svg"
import electricIcon from "../images/icons/electric.svg"
import hibernateIcon from "../images/icons/hibernate.svg"
import openapiIcon from "../images/icons/openapi.svg"
import queryIcon from "../images/icons/query.svg"
import bracketsIcon from "../images/icons/brackets.svg"
import rabbitmqIcon from "../images/icons/rabbitmq.svg"
import loopIcon from "../images/icons/loop.svg"
import checkIcon from "../images/icons/check.svg"
import puzzleMatchingIcon from "../images/icons/puzzle-matching.svg"
import macIcon from "../images/icons/mac.svg"
import intellijIcon from "../images/icons/intellij.svg"
import dockerIcon from "../images/icons/docker.svg"
import gitIcon from "../images/icons/git.svg"
import gitlabIcon from "../images/icons/gitlab.svg"
import bitbucketIcon from "../images/icons/bitbucket.svg"
import mavenIcon from "../images/icons/maven.svg"

export const skillsData = {
  skills: {
    ProgrammingLanguages: {
      Kotlin: {
        id: "kotlin",
        name: {
          es: "Kotlin",
          en: "Kotlin"
        },
        icon: kotlinIcon
      },
      Html: {
        id: "html",
        name: {
          es: "HTML",
          en: "HTML"
        },
        icon: htmlIcon
      },
      Css: {
        id: "css",
        name: {
          es: "CSS",
          en: "CSS"
        },
        icon: cssIcon
      },
      Php: {
        id: "php",
        name: {
          es: "PHP",
          en: "PHP"
        },
        icon: phpIcon
      }
    },
    ComputerScienceParadigms: {
      Microservices: {
        id: "microservices",
        name: {
          es: "Microservicios",
          en: "Microservices"
        },
        icon: thinClientIcon
      },
      DomainDrivenDesign: {
        id: "domain-driven-design",
        name: {
          es: "Diseño guiado por el dominio",
          en: "Domain-Driven Design"
        },
        icon: accuracyIcon
      },
      HexagonalArchitecture: {
        id: "hexagonal-architecture",
        name: {
          es: "Arquitectura hexagonal",
          en: "Hexagonal Architecture"
        },
        icon: hexagonIcon
      },
      ReactiveProgramming: {
        id: "reactive-programming",
        name: {
          es: "Programación reactiva",
          en: "Reactive Programming"
        },
        icon: electricIcon
      },
      Cqrs: {
        id: "cqrs",
        name: {
          es: "CQRS",
          en: "CQRS"
        },
        icon: queryIcon
      },
      Rest: {
        id: "rest",
        name: {
          es: "REST",
          en: "REST"
        },
        icon: bracketsIcon
      },
      FunctionalTesting: {
        id: "functional-testing",
        name: {
          es: "Testing funcional",
          en: "Functional Testing"
        },
        icon: checkIcon
      },
      IntegrationTesting: {
        id: "integration-testing",
        name: {
          es: "Testing de integración",
          en: "Integration Testing"
        },
        icon: puzzleMatchingIcon
      }
    },
    Technologies: {
      SpringFramework: {
        id: "spring-framework",
        name: {
          es: "Spring",
          en: "Spring"
        },
        icon: springIcon
      },
      Symfony: {
        id: "symfony",
        name: {
          es: "Symfony",
          en: "Symfony"
        },
        icon: symfonyIcon
      },
      WordPress: {
        id: "wordpress",
        name: {
          es: "WordPress",
          en: "WordPress"
        },
        icon: wordpressIcon
      },
      HibernateReactive: {
        id: "hibernate-reactive",
        name: {
          es: "Hibernate Reactive",
          en: "Hibernate Reactive"
        },
        icon: hibernateIcon
      },
      OpenAPI: {
        id: "open-api",
        name: {
          es: "OpenAPI",
          en: "OpenAPI"
        },
        icon: openapiIcon
      },
      RabbitMQ: {
        id: "rabbitmq",
        name: {
          es: "RabbitMQ",
          en: "RabbitMQ"
        },
        icon: rabbitmqIcon
      }
    },
    DevelopmentMethodologies: {
      Scrum: {
        id: "scrum",
        name: {
          es: "Scrum",
          en: "Scrum"
        },
        icon: loopIcon
      }
    },
    DevelopmentTools: {
      Mac: {
        id: "mac",
        name: {
          es: "Mac",
          en: "Mac"
        },
        icon: macIcon
      },
      IntelliJ: {
        id: "intellij",
        name: {
          es: "IntelliJ IDEA",
          en: "IntelliJ IDEA"
        },
        icon: intellijIcon
      },
      Docker: {
        id: "docker",
        name: {
          es: "Docker",
          en: "Docker"
        },
        icon: dockerIcon
      },
      Git: {
        id: "git",
        name: {
          es: "Git",
          en: "Git"
        },
        icon: gitIcon
      },
      GitLab: {
        id: "gitlab",
        name: {
          es: "GitLab",
          en: "GitLab"
        },
        icon: gitlabIcon
      },
      BitBucket: {
        id: "bitbucket",
        name: {
          es: "BitBucket",
          en: "BitBucket"
        },
        icon: bitbucketIcon
      },
      Maven: {
        id: "maven",
        name: {
          es: "Maven",
          en: "Maven"
        },
        icon: mavenIcon
      }
    },
    SoundTech: {
      PaEngineering: {
        id: "pa-engineering",
        name: {
          es: "Ingeniería de sonido",
          en: "PA Engineering"
        },
        icon: soundWaveIcon
      }
    }
  }
}
