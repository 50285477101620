import { createContext, useState } from "react"
import { Languages } from "../constants/languages"

export const LanguageContext = createContext(undefined)

export const LanguageContextProvider = ({ children }) => {
  const [activeStateLanguage, setStateLanguage] = useState(
    localStorage.language === Languages.En.tag ? Languages.En : Languages.Es
  )

  return (
    <LanguageContext.Provider value={{ activeLanguage: activeStateLanguage, setLanguage: setStateLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}
