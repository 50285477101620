import { useContext } from "react"
import { LanguageContext } from "../context/LanguageContext"
import { TODO } from "../constants/strings"
import { Languages } from "../constants/languages"

const useLanguage = () => {
  const { activeLanguage } = useContext(LanguageContext)

  const translate = o => {
    if (typeof o === "string") {
      return o
    } else if (o.hasOwnProperty(activeLanguage.tag) && o[activeLanguage.tag] !== TODO) {
      return o[activeLanguage.tag]
    } else if (o.hasOwnProperty(Languages.Es.tag) && o[Languages.Es.tag] !== TODO) {
      return o[Languages.Es.tag]
    }
    return TODO
  }

  return { t: translate }
}

export default useLanguage
